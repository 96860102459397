<script setup lang="ts">
import TelefonicaLogo from "@/assets/images/logos/telefonica.svg";

const { allowedGroupsForUser } = useMenu();

const isExpandable = useLocalStorage("show-menu", true);
</script>

<template>
  <aside class="menu-desktop" :class="isExpandable && 'expandable'">
    <button class="menu-desktop__toggle" @click="isExpandable = !isExpandable">
      <AtomsIcon :name="isExpandable ? 'chevron-left' : 'chevron-right'" />
    </button>
    <div class="menu-desktop__head" :class="isExpandable && 'expandable'">
      <TelefonicaLogo />
    </div>
    <div class="menu-desktop__items">
      <MoleculesExcel
        v-if="
          hasPermission({
            actions: Actions.UPLOAD_EXCEL,
          })
        "
        :only-icon="!isExpandable"
      />
      <MoleculesMenuDesktopItemsGroup
        v-for="(group, groupIndex) in allowedGroupsForUser"
        :key="groupIndex"
        :group="group"
        :only-icon="!isExpandable"
      />
    </div>
    <div class="menu-desktop__bottom">
      <MoleculesMenuDesktopBottom :only-icon="!isExpandable" />
    </div>
  </aside>
</template>

<style scoped lang="scss">
.menu-desktop {
  @apply relative min-h-[100vh] max-w-[200px] bg-[#F2F4FF] hidden lg:flex flex-col items-center justify-start gap-6 px-6 py-9;
  &:not(.expandable) {
    @apply min-w-[0] animate-slideLeftAndFade;
  }
  &.expandable {
    @apply min-w-[200px] xl:min-w-[250px] xl:max-w-[250px] animate-slideRightAndFade;
  }

  &__toggle {
    @apply absolute right-[-22px] top-0 h-[44px] w-[44px] rounded-r-[10px] flex items-center justify-center
    bg-[#F2F4FF] border border-[#F2F4FF] text-primary;
  }
  &__head {
    &:not(.expandable) {
      @apply w-[40px] overflow-hidden object-cover pl-[6px];
    }
    svg {
      @apply w-[160px] xl:w-[200px] h-[37px] mb-[38px];
    }
  }
  &__items {
    @apply w-full;
  }
  &__bottom {
    @apply w-full mt-auto;
  }
}
</style>
