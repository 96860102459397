import { type AxiosError } from "axios";
import {
  operationalApi,
  UploadExcelMessages,
  type UploadExcelErrors,
  type ExcelColumnError,
} from "~/api/operational";
import { excelUtils, type ExcelError } from "./excel-utils";

export const useExcel = () => {
  const isLoading = ref(false);
  const isSuccess = ref(false);
  const error = ref<ExcelError | undefined>();

  const queryClient = useQueryClient();
  const layout = inject("layout") as any;

  const excelFile = ref<File>();

  /**
   * Envia a planilha e trata os erros
   */
  async function handleSubmit() {
    if (!excelFile.value) {
      useNuxtApp().$toast("Nenhum arquivo selecionado!", {
        type: "error",
      });
      return;
    }
    isLoading.value = true;
    try {
      await operationalApi.uploadExcel(excelFile.value);

      // Limpa todas as queries
      queryClient.invalidateQueries();
      // Recarrega todo o conteúdo
      layout.refresh();
      layout.resetScroll();

      isSuccess.value = true;
    } catch (error) {
      isLoading.value = false;
      makeExcelErrors(error as AxiosError<UploadExcelErrors>);
      const _error = error as AxiosError;
      if (_error.response?.status && _error.response.status >= 500) {
        useNuxtApp().$error({
          error,
          message: "Erro ao enviar planilha!",
        });
      }
    } finally {
      isLoading.value = false;
    }
  }

  /**
   * Transforma o erro da requisição
   */
  function makeExcelErrors(_error: AxiosError<UploadExcelErrors>) {
    // Erro genérico
    if (_error.response?.status && _error.response?.status >= 500) {
      error.value = {
        type: "generic",
      };
      return;
    }

    const { data } = _error.response || {};
    if (data) {
      switch (data.message) {
        // Planilha vazia
        case UploadExcelMessages.EMPTY_SHEET:
          error.value = {
            type: "empty",
          };
          return;
        // Colunas com erro
        case UploadExcelMessages.DIVERGENT_COLS:
          error.value = {
            type: "divergent_columns",
            divergentColumns: excelUtils.parseDivergentCols(data.header_errors),
          };
          return;
        // Células com erro
        case UploadExcelMessages.CELLS_WITH_ERROR:
          error.value = {
            type: "cells_with_error",
            cellsWithError: excelUtils.parseCellsWithError(data.data_errors),
          };
          return;
      }
    }
  }

  function reset() {
    excelFile.value = undefined;
    isSuccess.value = false;
    error.value = undefined;
  }

  return {
    handleSubmit,
    error,
    isSuccess,
    isLoading,
    excelFile,
    reset,
  };
};
