<template>
  <div class="menu-desktop-bottom" :class="onlyIcon && 'items-center'">
    <div class="menu-desktop-bottom__user">
      <AtomsTooltip
        :title="`${userName} - ${userRole}`"
        side="right"
        :disabled="!onlyIcon"
      >
        <AtomsAvatar :width="42" :empty-icon-size="24" />
      </AtomsTooltip>
      <div class="menu-desktop-bottom__user__infos" v-if="!onlyIcon">
        <span class="break-words">{{ userName }}</span>
        <span class="break-words">
          {{ userRole }}
        </span>
      </div>
    </div>
    <AtomsTooltip title="Desconectar" :disabled="!onlyIcon" side="right">
      <button
        type="button"
        @click="$auth.handleLogout()"
        class="menu-desktop-bottom__logout"
      >
        <AtomsIcon name="log-out" class="text-gray-dark" />
        <span
          class="text-gray-dark text-[16px] leading-[20px]"
          v-if="!onlyIcon"
        >
          Desconectar
        </span>
      </button></AtomsTooltip
    >
  </div>
</template>

<script setup lang="ts">
defineProps<{
  onlyIcon?: boolean;
}>();

const userName = computed(() => {
  return useNuxtApp().$auth.user.value?.name || "Usuário";
});
const userRole = computed(() => {
  const role = useNuxtApp().$auth.user.value?.role;
  return role ? getRoleName(role) : "N/A";
});
</script>

<style scoped lang="scss">
.menu-desktop-bottom {
  @apply w-full flex flex-col;

  &__user {
    @apply w-full flex items-center gap-[10px] my-[10px];

    &__infos {
      @apply flex flex-col gap-[2px] text-left max-w-[150px];
      & > span:first-child {
        @apply text-primary font-bold text-[16px] leading-[20px] w-[150px];
      }
      & > span:last-child {
        @apply text-gray-dark font-light  text-[12px] leading-[20px] w-[150px];
      }
    }
  }

  &__logout {
    @apply w-full flex items-center gap-[10px] p-[10px] rounded-[10px];
  }
}
</style>
