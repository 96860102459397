<script setup lang="ts">
import AtomsIcon from "../icon/index.vue";

const props = withDefaults(
  defineProps<{
    type: "error" | "success";
    title?: string;
    description?: string;
    textClasses?: {
      title?: string;
      description?: string;
    };
    alignDescription?: "left" | "center";
  }>(),
  {
    alignDescription: "left",
  }
);

const iconNames = {
  error: "frown",
  success: "check-circle",
};
</script>

<template>
  <section class="feedback" :class="[type]">
    <AtomsIcon class="feedback__icon" :name="iconNames[type]" :size="46" />
    <span
      class="feedback__title"
      :class="textClasses?.title ? textClasses.title : 'text-[24px] leading-5'"
      v-if="title"
      >{{ title }}</span
    >
    <p
      class="feedback__description"
      :class="
        textClasses?.description
          ? textClasses.description
          : `text-[16px] leading-5 ${
              alignDescription === 'left'
                ? 'text-left ml-[-24px] mr-[-16px]'
                : 'text-center'
            } mb-4`
      "
      v-if="description"
      v-html="description"
    ></p>
    <slot name="extra"></slot>
  </section>
</template>

<style scoped lang="scss">
.feedback {
  @apply w-full py-6 px-6 flex flex-col items-center justify-center text-center;

  &__icon {
    @apply mb-8;
  }

  &__title {
    @apply font-bold mb-4;
  }

  &__description {
    @apply text-gray-dark;
  }

  &.error {
    .feedback__icon,
    .feedback__title {
      @apply text-danger;
    }
  }
  &.success {
    .feedback__icon,
    .feedback__title {
      @apply text-primary;
    }
  }
}
</style>
