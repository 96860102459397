<script setup lang="ts">
import { filetypes } from "@/utils/files";
import { useExcel } from "./use-excel";
import ErrorFeedback from "./error-feedback.vue";

const props = defineProps<{
  onlyIcon?: boolean;
}>();

const showModal = ref(false);

const { handleSubmit, reset, error, isSuccess, isLoading, excelFile } =
  useExcel();

const closeModal = () => {
  showModal.value = false;
};

watch(
  () => showModal.value,
  (_v) => {
    if (!_v) {
      reset();
    }
  }
);

const alignContentTop = computed(() => {
  if (
    error.value &&
    (error.value.type === "cells_with_error" ||
      error.value.type === "divergent_columns")
  )
    return true;
  return false;
});
</script>

<template>
  <MoleculesExcelDesktopButton
    @click="showModal = true"
    :only-icon="onlyIcon"
  />
  <AtomsModal
    title="Adjudicar"
    v-model:open="showModal"
    :content-center="!alignContentTop"
    :scroll-content="true"
    :closable="!isLoading"
  >
    <div
      class="w-full flex flex-col h-full"
      :class="!alignContentTop && 'justify-center'"
    >
      <AtomsFile
        title="Anexe o Excel de Adjudicação"
        description="Arquivo excel"
        v-model="excelFile"
        :accept="filetypes.excel"
        v-if="!error && !isSuccess"
      />
      <AtomsFeedback
        v-else-if="isSuccess && !error"
        type="success"
        description="Adjudicação concluída!"
        :text-classes="{
          description: 'text-[24px] leading-5',
        }"
      />
      <ErrorFeedback v-else-if="error && !isSuccess" :error="error" />
    </div>

    <template #actions>
      <!-- AÇÕES PADRÕES -->
      <div class="excel__actions animate-fadeIn" v-if="!isSuccess && !error">
        <AtomsButton
          link
          label="Cancelar"
          theme="primary"
          @click="closeModal"
          :disabled="isLoading"
        />
        <AtomsButton
          label="Enviar"
          theme="primary"
          rounded
          @click="handleSubmit"
          :loading="isLoading"
        />
      </div>
      <!-- AÇÕES DE SUCESSO -->
      <div class="excel__actions animate-fadeIn" v-if="isSuccess && !error">
        <AtomsButton
          label="Fechar"
          theme="primary"
          rounded
          @click="closeModal"
        />
      </div>
      <!-- AÇÕES DE ERRO -->
      <div class="excel__actions animate-fadeIn" v-if="error && !isSuccess">
        <AtomsButton
          link
          label="Cancelar"
          theme="primary"
          @click="closeModal"
        />
        <AtomsButton
          label="Tentar novamente"
          theme="primary"
          rounded
          @click="reset"
        />
      </div>
    </template>
  </AtomsModal>
</template>

<style scoped lang="scss">
.excel {
  &__actions {
    @apply w-full flex justify-center items-center gap-4 pt-4;
  }
}
</style>
./use-excel
