<script setup lang="ts">
import type { ExcelError } from "./excel-utils";

const props = defineProps<{
  error: ExcelError;
}>();

const errorDetails = computed<{
  title: string;
  description: string;
  alignDescription: "left" | "center";
}>(() => {
  switch (props.error.type) {
    case "generic":
      return {
        title: "Falha no Envio",
        description:
          "Houve falha no envio do documento.<br/>Verifique se os dados estão corretos e tente novamente.",
        alignDescription: "center",
      };
    case "empty":
      return {
        title: "Planilha vazia",
        description:
          "A planilha do excel está vazia. Por favor, envie um novo arquivo.",
        alignDescription: "center",
      };
    case "divergent_columns":
      return {
        title: "Colunas divergentes",
        description:
          "A disposição de algumas colunas no seu Excel parece estar fora do padrão, possivelmente fora de ordem ou até mesmo excluídas. Por favor, verifique a lista abaixo para mais detalhes:",
        alignDescription: "left",
      };
    case "cells_with_error":
      return {
        title: "Células com erros",
        description: "Algumas células no corpo do excel estão com erros:",
        alignDescription: "left",
      };
  }
});
</script>

<template>
  <AtomsFeedback
    type="error"
    :title="errorDetails?.title"
    :description="errorDetails?.description"
    :align-description="errorDetails.alignDescription"
    class="absolute top-o left-0"
  >
    <template #extra>
      <!-- CÉLULAS COM ERROS -->
      <div
        v-if="error.type === 'cells_with_error' && error.cellsWithError"
        class="w-full"
      >
        <ul class="list-disc text-left text-gray-dark text-[16px]">
          <li
            v-for="(message, index) in error.cellsWithError"
            :key="index"
            class="mb-3"
          >
            <span class="break-words">{{ message }}</span>
          </li>
        </ul>
      </div>
      <!-- COLUNAS COM ERROS -->
      <div
        v-if="error.type === 'divergent_columns' && error.divergentColumns"
        class="w-full flex flex-col"
      >
        <!-- ORDEM CORRETA-->
        <ul
          class="list-disc text-left text-gray-dark text-[16px] ml-[0px]"
          v-if="error.divergentColumns.correctOrder?.length"
        >
          <li>
            <span>As colunas devem ser ordenadas da seguinte forma: </span>
          </li>
          <AtomsExpandable :initial-content-height="170">
            <div class="w-full flex flex-col mt-2">
              <div
                class="w-full flex items-center gap-4"
                v-for="item in error.divergentColumns.correctOrder"
              >
                <AtomsIcon name="check" :size="24" class="text-primary" />
                <span
                  class="text-gray-dark text-[16px] leading-6 break-words"
                  >{{ item }}</span
                >
              </div>
            </div>
          </AtomsExpandable>
        </ul>
        <!-- COLUNAS NÃO PERMITIDAS-->
        <ul class="list-disc text-left text-gray-dark text-[16px]">
          <li class="mt-3" v-if="error.divergentColumns?.notAllowed?.length">
            <span class="break-words text-gray-6">
              Coluna(s) não permitidas ou com nomenclatura errada:
              {{
                error.divergentColumns.notAllowed
                  .map((v) => `"${v}"`)
                  .join(", ")
              }}
            </span>
          </li>
          <!-- COLUNAS FALTANTES -->
          <li class="mt-3" v-if="error.divergentColumns?.missing?.length">
            <span class="break-words text-gray-dark">
              Coluna(s) faltantes:
              {{
                error.divergentColumns.missing.map((v) => `"${v}"`).join(", ")
              }}
            </span>
          </li>
        </ul>
      </div>
    </template>
  </AtomsFeedback>
</template>

<style lang="scss" scoped></style>
